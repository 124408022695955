import React from 'react'
import Banner from '../../components/components-Quem_Somos/Banner/Banner'
import Card01 from '../../components/components-Quem_Somos/Card01/Card01'
import Card02 from '../../components/components-Quem_Somos/Card02/Card02'
import Card03 from '../../components/components-Quem_Somos/Card03/Card03'
import Card04 from '../../components/components-Quem_Somos/Card04/Card04'
import Card05 from '../../components/components-Quem_Somos/Card05/Card05'

function QuemSomos() {
    return (
        <>
            <Banner />
            <Card01 />
            <Card02 />
            <Card03 />
            <Card04 />
            <Card05 />
        </>
    )
}

export default QuemSomos