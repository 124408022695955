import React from 'react'
import { CardContainer, CardContent, ContainerImg, ContainerText, Image,Text } from './StyleCard04'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useInView } from 'react-intersection-observer';

function Card04() {
    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    }

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (

        <CardContainer ref={ref}>
            <CardContent 
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={animationVariants}
            >
                <ContainerImg>
                    <Image src='./phone_laptop.png'/>
                </ContainerImg>
                
                <ContainerText 
                >
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/>  Sistema operando online/offline</Text> 
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/>  Controle de jornada de trabalho</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Check-in e check-out com controle de localização</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Controle de verba</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Combos/verba flex</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Módulo negociações (promoções) personalizadas</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Sugestão de pedido via WhatsApp</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Layout simples e intuitivo</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Dashboards de acompanhamento de meta e gerenciamentos</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Relatórios personalizados</Text>
                </ContainerText>
            </CardContent>
        </CardContainer>
    )
}

export default Card04