import styled from "styled-components";

export const CardContainer = styled.div `
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    
`
export const ContainerText = styled.div `
    display: flex;
    width: 80%;
    padding-top: 60px;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 60px;
    
    flex-direction: column;
    align-items: center;

    @media(max-width: 1024px) {
        padding: 40px;
        width: 100%;
    }

    @media(max-width: 500px) {
        padding: 40px;
        width: 100%;
    }
`

export const Text = styled.span `
    text-align: justify;
    font-size: 16px;
    padding-bottom: 15px;
    color: #8a8a8a;
    font-family: 'open-sans', sans-serif;

    @media(max-width: 500px) {
        font-size: 12px;
    }
`