import React from 'react'
import { CardContainer, CardImage,  ContainerGreen,  ContainerSubtitle,  ContainerText, Text, Text2, Title, Title2 } from './StyleCard04'
import { useInView } from 'react-intersection-observer';


function Card04() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    }; 

    return (
        <CardContainer ref={ref}>
            <ContainerText 
                initial="hidden" 
                animate={inView ? 'visible' : 'hidden'} 
                variants={animationVariants}

            >
                <Title>
                    Para que complicar se 
                </Title>
                
                <Title2>
                    podemos facilitar
                </Title2>

                <ContainerSubtitle>
                    <Text>
                        Vivemos o dia a dia de uma distribuidora, 
                        conhecemos os procedimentos que existem dentro de um negócio de distribuição e sabemos que já são complexos o suficiente. 
                        Por isso, nossos sistemas trazem uma solução prática que facilita e aproxima todos os envolvidos no processo, do início ao fim.
                    </Text>    

                    <Text>
                        Buscamos otimizar o tempo e organizar a rotina dos usuários, com funcionalidades focadas na estratégia de vendas e gestão do negócio. Isso só é possível porque entendemos as necessidades e a rotina dentro da distribuidora. 
                    </Text>

                    <Text>
                        E é assim, com pensamento focado em atender cliente e sempre buscando inovações, que construímos e buscamos sempre a evolução dos nossos produtos, de acordo com a realidade.
                    </Text>

                    <Text>
                        Na distribuição e no atacado, a VEPLEX ajuda a coordenar e descomplicar suas compras, vendas e rotas.
                    </Text>
                </ContainerSubtitle>

                <ContainerGreen>
                    <Text2>
                        Mais agilidade | Mais simplicidade | Mais controle | Mais resultados
                    </Text2>
                </ContainerGreen>
                
            </ContainerText>
            <CardImage src={require('../../../images/IMG_6953_edited.png')} />
        </CardContainer>
    )
}

export default Card04