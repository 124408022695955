import React from 'react'
import { CardContainer, ContainerText, Text, Title } from './StyleCard02'

function Card02() {
    return (
        <CardContainer>
            <ContainerText>
                <Title>UP Vendas</Title>

                <Text>
                    Empresas do ramo de distribuição/atacado sabem bem o quão importante é ganhar tempo e agilizar a entrega de informações para potencializar as vendas do seu time 
                    e entregar um ótimo atendimento a seus clientes, por isso você precisa do UP Vendas na sua empresa!
                </Text>

                <Text>
                    O UP Vendas foi construido com o objetivo de ajudar você a gerenciar seus clientes e seu time comercial de maneira fácil, prática e eficaz. 
                    Com ele, você pode acompanhar o progresso de suas vendas, dar autonomia para seus vendedores proporcionarem as melhores negociações, 
                    permitir negócios fechados em tempo real, esteja seu vendedor com ou sem internet ativa, gerenciar e analisar métricas de desempenho, gerar relatórios e muito mais. 
                    E ainda, conta com um design super intuitivo e prático para o seu time já começar a usar hoje mesmo.
                </Text>
            </ContainerText>
        </CardContainer>
    )
}

export default Card02