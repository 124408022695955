import styled from "styled-components";
import {motion} from 'framer-motion';

//CONTAINER

export const CardContainer = styled.div `
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #B9CE12;
    flex-direction: column;
    
    @media (max-width: 900px) {
    }

    @media (max-width: 500px) {
        justify-content: center;
        align-items: center;
    }
`;

//CONTAINER TITULO 

export const ContainerText = styled(motion.div) `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% ;
    padding: 20px;
    transition: 2s ease;

    @media (max-width: 900px) {
        justify-content: center;
        align-items: center;
        width: 80%;
        
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 500px) {
        width: 100%;
        padding-right: 5px;
        padding-top: 5px;
        justify-content: center;
        align-items: center;
    }

`;

//TEXTO 

export const CardText = styled.span `
    display: flex;
    font-weight: 800;
    font-size: 40px;
    color: #fff;
    justify-content: center;
    text-align: center;

    @media (max-width: 1200px) {
        font-size: 20px;
    }

    @media (max-width: 900px) {
        font-size: 30px;
        width: 100%;
        text-align: center;
        justify-content: center;

    }

    @media (max-width: 500px) {
        justify-content: center;
        text-align:center;
        width: 100%;
    }

`
export const ContainerCardProducts = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-top: 10px;
    padding-bottom: 40px;
    gap: 10px;

    @media (max-width: 900px) {
        flex-direction: column;
        
    }
`;


