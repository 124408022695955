import React from 'react'
import { CardContainer, CardContent, ContainerText, Image } from './StyleCard05'
import { useInView } from 'react-intersection-observer';

function Card05() {
    const array = [
        {
            title: 'Eficiência',
            text: 'Otimize e construa de forma estratégica e fácil seus roteiros, gerando rotas mais eficazes e cobrindo de forma completasuas áreas de atendimento.'
        },
        
        {
            title: 'Praticidade',
            text: 'De forma simples e intuitiva, acesse todas as informações necessárias para a sua tomada de decisão estratégica.'
        },

        {
            title: 'Economia',
            text: 'Deixe que nossa inteligência trace as melhores rotas levando em consideração distâncias KM e tempo de atendimento, proporcionando otimização de tempo e redução de custos.'
        },

        {
            title: 'Controle',
            text: 'Acompanhe por geolocalização toda a sua equipe, identificando produtividade, ajustes de rotas a serem feitas, reembolsos corretos a serem realizados e visitas efetivadas em sua carteira de clientes.'
        }
    ]

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    }

    return (
        <CardContainer ref={ref}>
            <CardContent>
                {array.map((item, index) => (
                    <ContainerText align={index%2 === 0 ? 'flex-start' : 'flex-end'}
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={animationVariants}
                    >
                        <h1>{item.title}</h1>
                        <p>{item.text}</p>
                    </ContainerText>
                ))}
                <Image src={('./uprotasbranco.png')}/>
            </CardContent>
        </CardContainer>
    )
}

export default Card05