import React from 'react'
import { CardContainer, ContainerText, Text, Title } from './StyleCard01'


function Card01() {
    return (
        <CardContainer>
            <ContainerText>
                <Title>
                    UP Promotor
                </Title>

                <Text>
                    Com o UP Promotor, sua equipe de merchandising terá tudo o que precisa para gerenciar tarefas e visitas, 
                    enquanto garante que as mercadorias estejam sempre reabastecidas e prontas para venda. 
                    Ainda, disponibilizamos para você gestor, o controle de todas as tarefas desempenhadas por eles. 
                </Text>

                <Text>
                    Nosso UP Promotor permite enviar pelo próprio app, registros das tarefas concluídas, fotos dos espaços e informações relevantes do ponto de venda.
                </Text>

                <Text> 
                    Isso significa que você pode manter sua equipe e seus fornecedores parceiros 
                    sempre atualizados sobre o que foi feito em cada ponto de venda, 
                    garantindo que você tenha informações mais precisas para tomar e criar estratégias de merchandising
                </Text>
            </ContainerText>
            
        </CardContainer>
    )
    
}

export default Card01