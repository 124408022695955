import React from 'react'
import Banner from './components/Banner/Banner'
import Card01 from './components/Card01/Card01'
import Card02 from './components/Card02/Card02'
import Card03 from './components/Card03/Card03'
import Card04 from './components/Card04/Card04'

function UpPromotor() {
    return (
        <>
            <Banner />
            <Card01 />
            <Card02 />
            <Card03 />
            <Card04 />
        </>
    )
}

export default UpPromotor