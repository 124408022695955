import React from 'react'
import { CardContainer, ContainerText, Text } from './StyleCard03'
import { useInView } from 'react-intersection-observer';


function Card03() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const fadeInAnimation = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 3} },
    };

    return (
        <CardContainer ref={ref} >
            <ContainerText 
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={fadeInAnimation}
            >
                <Text>
                    Sua equipe de vendas focada em fechar
                </Text>
                <Text>
                    mais e melhores negócios, enquanto você
                </Text>
                <Text>
                    acompanha tudo de perto.
                </Text>

            </ContainerText>
        </CardContainer>
    )
}

export default Card03