import styled from "styled-components";

export const CardContainer = styled.div `
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    
`

export const ContainerText = styled.div `
    width: 80%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding-right: 150px;
    padding-left: 150px;
    padding-top: 50px;
    padding-bottom: 50px;


    @media(max-width: 1024px) {
        width: 100%;
        padding: 20px;
    }

    @media(max-width: 500px) {
        padding: 10px;
        width: 100%;
    }
`

export const Title = styled.span `
    font-size: 56px;
    font-weight: 400;
    color: #3F4349;
    padding-bottom: 30px;
    font-family: 'poppins', sans-serif;
    
    @media(max-width: 500px) {
        font-size: 35px;
        width: 100%;
        align-items: center;
        text-align: center;
    }

`
export const Text = styled.span `
    font-size: 16px;
    color: #8a8a8a;
    padding: 10px;
    font-family: 'open-sans', sans-serif;
    text-align: justify;
`

