import styled from "styled-components";

export const CardContainer = styled.div `
    width: 100%;
    background-color: #b9ce12;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const ContainerText = styled.div `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
`
export const Text = styled.span `
    font-size: 48px;
    color: #fff;
    font-family: ' poppins', sans-serif;
    text-align: center;
`
