import React from 'react'
import { BannerContainer, BannerImage, OverlayImage } from './StyleBanner'

function Banner() {
    return (
        <BannerContainer>
            <BannerImage src='./manpageuprotas.jpg'/>
            <OverlayImage src='./upRotas.png'/>
        </BannerContainer>
    )
}

export default Banner