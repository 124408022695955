import React from 'react'
import { CardContainer, CardContent, ContainerText, Image, Text } from './StyleCard01'
import { useInView } from 'react-intersection-observer';

function Card01() {
    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    }

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <CardContainer ref={ref}>
            <CardContent>
                <ContainerText
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={animationVariants}
                >
                    <Text>
                        O controle e economia para seu processo comercial, tudo em uma ferramenta só
                    </Text>
                </ContainerText>

                <Image src='./pc.png'/>
            </CardContent>
        </CardContainer>
    )
}

export default Card01