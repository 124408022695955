import React from 'react';
import { CardContainer, CardImage, CardText, Container, Line } from './styleCardProduto';

type Props = {
    imagem: string;
    texto: string;
};

const CardProduto = ({ imagem, texto }: Props) => {
    return (
        <CardContainer
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
        >
            <CardImage src={imagem} />

            <Container>
                <CardText>{texto}</CardText>

            </Container>
            <Line />
        </CardContainer>
    );
};

export default CardProduto;