import React from 'react'
import { Container, ContainerLinks, ContainerNetworks, ContainerText, DivLogo, FooterLink,  Logo, Text, DivIcons, A } from './styleFooter'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
    return (
        <Container>
            <DivLogo>
                <Logo src={require("../../images/Ativo 5_100x-8.png")} />
            </DivLogo>

            <ContainerText>
                <Text>
                    54 99163 0943
                </Text>

                <Text>
                    Rua Antonio Araújo, 1046, Sala
                </Text>

                <Text>
                    801 - Centro - Passo Fundo - RS
                </Text>

            </ContainerText>

            <ContainerLinks>
                <FooterLink to="/" >Início</FooterLink>
                <FooterLink to="/quem-somos">Quem-Somos</FooterLink>
                <FooterLink to="/nossas-solucoes">Nossas Soluções</FooterLink>
                <FooterLink to="/contato">Contato</FooterLink>
            </ContainerLinks>

            <ContainerNetworks>
                <Text>
                    Redes Sociais
                </Text>

                <DivIcons>
                    <A href="https://www.facebook.com/profile.php?id=100089668626255" >
                        <FacebookIcon />
                    </A>

                    <A href="https://www.instagram.com/veplexsistemas/">
                        <InstagramIcon />
                    </A>
                    <A href="https://www.linkedin.com/company/veplexsistemas/">
                        <LinkedInIcon />
                    </A>
                </DivIcons>
            </ContainerNetworks>
        </Container>
    )
}

export default Footer