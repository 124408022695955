import React from 'react'
import { CardContainer, CardContent, ContainerImg, ContainerText, Image,Text } from './StyleCard04'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useInView } from 'react-intersection-observer';

function Card04() {
    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    }

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (

        <CardContainer ref={ref}>
            <CardContent 
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={animationVariants}
            >
                <ContainerImg>
                    <Image src='./tablet.png'/>
                </ContainerImg>
                
                <ContainerText 
                >
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Construções de rotas otimizadas em localização, tempo e KM</Text> 
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/>  Visualização, em mapa, de conflitos de rotas</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Visualização da equipe de vendas/merchandising em tempo real</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Relatórios personalizados</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Acompanhamento de visitas efetivadas</Text>

                </ContainerText>
            </CardContent>
        </CardContainer>
    )
}

export default Card04