import React from 'react'
import { CardContainer,  ContainerText, TextPurple, TextWhite } from './StyleCard02'
import { useInView } from 'react-intersection-observer';


function Card02() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const fadeInAnimation = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 3} },
    };


    return (
        <CardContainer >
            <ContainerText ref={ref}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={fadeInAnimation}
            >
                
                <TextWhite>
                    Melhorando o dia a dia de uma 
                </TextWhite>

                <TextWhite>
                    rede enorme de pessoas
                </TextWhite>

                <div>
                    <TextPurple>
                        melhorando todo o processo
                    </TextPurple>

                </div>
            </ContainerText>
        </CardContainer>
    )
}

export default Card02