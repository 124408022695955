import styled from "styled-components";

export const CardContainer = styled.div `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 500px;
    
    @media (max-width: 1500px) {
        height: 800px;
    } 

    @media (max-width: 500px) {
        height: auto;
    }

`
export const ContainerText = styled.div `
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
    
    @media (max-width: 500px) {
        width: 70%;
    }
`

export const Title = styled.span `
    font-size: 60px;
    color: #b9ce12;
    font-family: 'poppins', sans-serif;
    text-align: start;
    padding-bottom: 30px;
    padding-top: 50px;
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: 40px;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    @media (max-width: 500px) {
        font-size: 30px;
    }
`

export const Text = styled.span `
    font-size: 20px;
    color: #8a8a8a;
    font-family: 'open-sans', sans-serif;
    text-align: justify;
    padding-bottom: 30px;
    line-height: 1.5;

    @media (max-width: 768px) {
        font-size: 16px;
        padding-bottom: 20px;
    }

    @media (max-width: 500px) {
        font-size: 14px;
    }
`