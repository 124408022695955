import React from 'react'
import { CardContainer, ContainerContent, ContainerText, Line, Text } from './StyleCardHome04'
import Carrossel from '../carrossel/Carrossel'

function CardHome04() {
    return (
        <CardContainer>
            <ContainerContent>
                <ContainerText>
                    <Text>
                        Quem já
                        está crescendo com a Veplex
                    </Text>
                    
                </ContainerText>

                    <Line />
                <Carrossel />

            </ContainerContent>
        </CardContainer>
    )
}

export default CardHome04