import styled from "styled-components";

export const CardContainer = styled.div `
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const ContainerText = styled.div `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 60px;
    
    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        flex-direction: column;
    }
`

export const DivText = styled.div `
    display: flex;
    width: 100%;
    height: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 500px) {
        padding-bottom: 30px;
    }
    
`


export const Title = styled.span `
    font-size: 30px;
    color: #703189;
    font-family: 'open-sans', sans-serif;
    text-align: start;
    padding-bottom: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-align: center;
    padding-top: 10px;

    @media (max-width: 1440px) {
        font-size: 25px;
    }
`



export const Text = styled.span `
    font-size: 20px;
    color: #8a8a8a;
    font-family: 'open-sans', sans-serif;
    text-align: center;

    @media (max-width: 1440px) {
        font-size: 15px;
    }
    
    @media (max-width: 1024px) {
        text-align: center;
    }
`


export const ContainerMap = styled.div `
    display: flex;
    width: 50%;
    padding-top: 80px;
    padding-bottom: 100px;
    align-items: center;
    justify-content: center;
`

export const Map = styled.div `
    width: 100%;
    height: 400px;
    background-color: #000;
    align-items: center;
    justify-content: center;
`

export const MapText = styled.span`
    font-size: 16px;
    color: #fff;
    font-family: 'open-sans', sans-serif;
    text-align: center;
`


