import { motion } from "framer-motion";
import styled from "styled-components";

export const CardContainer = styled.div `
    width: 100%;
    height: 100%;
    background-color: #141416;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `

export const ContainerText = styled(motion.div)`
    display: flex;
    width: 80%;
    padding-top: 60px;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 60px;
    transition: 4s;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.span `
    font-size: 44px;
    color: #fff;
    font-family: 'poppins', sans-serif;
    text-align: center;
    padding-bottom: 30px;
`;

export const Subtitle = styled.span `
    font-size: 25px;
    color: #fff;
    font-family: 'poppins', sans-serif;
    text-align: center;
    padding-bottom: 30px;
`;

export const Text = styled.span `
    font-size: 16px;
    color: #fff;
    font-family: 'open-sans', sans-serif;
    text-align: center;
    padding-bottom: 30px;
`;

export const ContainerBlack= styled(motion.div) `
    display: flex;
    width: 95%;
    background-color: #1B1C1ECC;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    transition: 3s;
    padding-top: 40px;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
`;

export const ContainerImg = styled.div `
    display: flex;
    width: 287px;
    height: 323px;
    flex-direction: column;
    justify-content:end;
    align-items: end;
    position: relative; 
    margin-right: 20px; 
`

export const Img = styled.img `
    width: 287px;
    height: 323px;
    object-fit: cover;
`

export const DivGreen = styled.div `
    display: flex;
    width: 255px;
    height: 79px;
    background-color: #b9ce12;
    padding: 10px;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    position: absolute; /* Posicionamento absoluto em relação ao ContainerImg */
    top: 80%; /* Posicionar abaixo da imagem */
    right: -20px; /* Ajustar conforme necessário para posicionar corretamente */
`;


export const TextWhite = styled.span `
    font-size: 20px;
    color: #fff;
    font-family: 'open-sans', sans-serif;
    text-align: center;
    padding-bottom: 5px;
`
export const TextGray = styled.span `
    font-size: 16px;
    color: #8a8a8a;
    font-family: 'open-sans', sans-serif;
    text-align: center;
    padding-bottom: 5px;
`