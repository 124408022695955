import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CardContainer = styled(motion.div)`
    width: 100%;
    min-height: 550px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
    padding: 20px;
    --webkit-box-shadow: 3px -1px 30px -4px rgba(0, 0, 0, 1);
    -moz-box-shadow: 3px -1px 30px -4px rgba(0, 0, 0, 1);
    box-shadow: 3px -1px 30px -4px rgba(0, 0, 0, 1);
    transition: 2s ease;

    @media (max-width: 1024px) {
        min-height: 500px;
    }

    @media (max-width: 900px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 100%;
        
    }
`;

export const Container = styled.div`
    flex-grow: 2;
`;

export const CardText = styled.p`
    font-size: 14px;
    color: #8a8a8a;
    font-family: 'poppins', sans-serif;
    text-align: justify;

    @media (max-width: 1200px) {
        letter-spacing: -1px;
        word-spacing: 0.5px;
        font-size: 12px;
    }

    @media (max-width: 900px) {
        font-size: 15px;
    }

    @media (max-width: 500px) {
    }
`;

export const ContainerLine = styled.div`
    z-index: 99;
    background-color: red;
`;

export const Line = styled.div`
    width: 50%;
    height: 6px;
    border-radius: 99px;
    background-color: #3f4349;
    margin-bottom: 30px;
`;

export const CardImage = styled.img`
    width: 40%;
    aspect-ratio: 3/2;
    object-fit: contain;

    @media (max-width: 1200px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        width: 30%;
    }

    @media (max-width: 500px) {
    }
`;

export const A = styled.a`
    text-decoration: none;
`