import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
    CardContainer,
    CardContent,
    CardImage,
    CardText,
    CardTitle,
    ContainerText,
    GeneralContainer,
} from './styleCardHome01';

function CardHome01() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    };

    return (
        <CardContainer>
            <GeneralContainer ref={ref}>
                <CardContent>
                    <ContainerText
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={animationVariants}
                    >
                        <div>
                            <CardTitle>
                                Foco total no seu negócio
                            </CardTitle>

                            <CardText>
                                Justamente por ter nascido dentro de uma das maiores distribuidoras do Brasil,
                                entendemos e conhecemos os desafios e as dores que sua empresa precisa solucionar no dia a dia do seu negócio;
                                buscando contribuir para que a sua equipe renda mais, otimize seus processos e, também, tenha controle sobre os mais diversos setores e, o principal,
                                escalar suas vendas de forma previsível, eficiente, automatizada e segura.
                            </CardText>
                        </div>

                        <CardText>
                            Uma empresa dedicada à criação e desenvolvimento de softwares como soluções integradas que otimizam os processos,
                            aumentam a produtividade e permitem uma visão integrada dos processos para a sua empresa crescer mais.
                        </CardText>
                    </ContainerText>
                </CardContent>

                <CardImage src={require('../../images/11316.jpg (1).png')} alt="card" />
            </GeneralContainer>
        </CardContainer>
    );
}

export default CardHome01;
