import styled from "styled-components";

export const BannerContainer = styled.div`
    position: relative;
    width: 100%;
`
export const Image = styled.img`
    width: 100%;
    max-height: 800px; /* Altura máxima ajustável conforme necessário */
    height: auto;
    display: block;
    object-fit: cover;
`

export const Overlay = styled.img `
    position: absolute;
    top: 40%;
    left: 25%;
    transform: translate(-50%, -50%);
    max-width: 20%; /* Ajuste conforme necessário */
    opacity: 0.8; /* Ajuste conforme necessário */
`;

export const ContainerText = styled.div` 
    width: 80%;
    position: absolute;
    margin: 0 auto;
    top: 90%;
    left: 10%;
    right: 10%;
    align-items: center;
    justify-content: center;
    background-color: #b9ce12;
    /* padding-bottom: 10px;
    padding-top: 10px; */

    @media (max-width: 1200px) {
        top: 60%;
    }
`

export const Text = styled.span `
    font-size: 30px;
    text-align: center;
    color: white;

    @media (max-width: 768px) {
        font-size: 18px;
    }

    @media (max-width: 500px) {
        font-size: 14px;
    }
`