import { motion } from "framer-motion";
import styled from "styled-components";


export const CardContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #703189;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
        flex-direction: column;
    }

    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

export const ContainerText = styled(motion.div)`
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
    transition: 2s;

    @media (max-width: 1024px) {
        width: 100%;
        padding: 50px;
    }
`;
export const Title = styled.span`
    font-size: 42px;
    color: #fff;
    font-family: "poppins", sans-serif;
    text-align: start;


`;

export const Title2 = styled.span`
    font-size: 42px;
    color: #b9ce12;
    font-family: "poppins", sans-serif;
    text-align: start;
    padding-bottom: 14px;
`;

export const ContainerSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const Text = styled.span`
    font-size: 20px;
    color: #fff;
    font-family: "open-sans", sans-serif;
    text-align: justify;
    padding-bottom: 14px;
`;

export const CardImage = styled.img`
    width: 30%;
    object-fit: cover;

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const ContainerGreen = styled.div`
    display: flex;
    width: 80%;
    background-color: #b9ce12;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    border-radius: 8px;
    padding: 8px;
    font-family: "poppins", sans-serif;
`;

export const Text2 = styled.span`
    font-size: 20px;
    color: #ffffff;
    font-family: "poppins-extralight, poppins", sans-serif;
    text-align: center;
    padding-bottom: 2px;
`;