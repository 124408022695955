

import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    width: 100%;
    background-color: #141416;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const DivLogo = styled.div`
    padding: 10px;
    justify-content: left;
    width: fit-content;

    @media (max-width: 500px) {
        justify-content: center;
    }
`;
export const Logo = styled.img`
    aspect-ratio: 3/2;
    object-fit: contain;
    width: 140px;
`;

export const ContainerText = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const Text = styled.span`
    padding: 5px;
    font-size: 18px;
    color: #fff;
    text-align: left;
    font-family: "open-sans", sans-serif;
`;
export const ContainerLinks = styled.div`
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 500px) {
        flex-direction: column;
        gap: 8px;
    }

`;

export const FooterLink = styled(Link)`
    padding: 5px;
    font-size: 18px;
    color: #fff;
    font-family: "open-sans", sans-serif;
`;

export const ContainerNetworks = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
`;
export const DivIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: left;
    gap: 2px;
`;

export const A = styled.a`
    display: flex;
    text-decoration: none;
    color: #fff;
    gap: 1px;
`;

