import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const CardContainer = styled.div `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #b9ce12;
    position: relative;
    padding: 32px 0;
`
export const CardContent = styled.div`
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
`

type containerTextProps = {
    align: 'flex-start' | 'flex-end'
}

export const ContainerText = styled(motion.div)<containerTextProps> `
    width: 100%;
    z-index: 10;
    background-color: #ffffff30;
    border-radius: 100px;
    border: 1px solid #ffffff;
    padding: 15px;
    transition: 2s ease;

    @media (min-width: 768px) {
        width: 40%;
        ${({align}) => css`
            

            align-self: ${align};
        `
    }
        h1, p {
            padding: 0;
            margin: 0;
            text-align: center;
            color: #ffffff;
            font-size:16px;
        }
    }
`

export const Image = styled.img `
    width: 10%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 500px) {
        width: 50%;
        opacity: 0.8;
    }
`