import React from 'react'
import { CardContainer, ContainerText, Text, Title } from './StyleCard02'

function Card02() {
    return (
        <CardContainer>
            <ContainerText>
                <Title>
                    UP Rotas
                </Title>

                <Text>
                    Com o UP Rotas, você pode planejar o roteiro de visitas da sua equipe comercial com facilidade. 
                    Nossa ferramenta permite que você crie rotas personalizadas para seu time comercial, 
                    de acordo com a localização de seus clientes e as prioridades de vendas, economizando dinheiro e ganhando tempo, 
                    com rotas inteligentes e otimizadas.
                </Text>

                <Text>
                    Com o acompanhamento por geolocalização, você pode monitorar em tempo real o deslocamento da equipe de vendas e ter uma visão completa do progresso das visitas.
                </Text>
            </ContainerText>
        </CardContainer>
    )
}

export default Card02