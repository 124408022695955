import React from 'react'
import { CardContainer, CardContent, CardImage, CardText, CardTitle, ContainerText } from './styleCardHome03'
import { useInView } from 'react-intersection-observer';


const animationVariants = {
    hidden: { x: 1000, opacity: 0 },
    visible: { x: 0, opacity: 1 },
};

function CardHome03() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <CardContainer ref={ref}>
            <CardImage src={('./imgCardHome03.png')} alt="imagem de celular" />
            <CardContent>

            <ContainerText 
                initial= "hidden" 
                animate={inView ? 'visible' : 'hidden'} 
                variants={animationVariants}
            >

                    <CardTitle>
                        Qualidade Eficiência Produtividade Resultado
                    </CardTitle>

                
                    <CardText>
                        Sistemas com acessos em versão desktop e mobile, com interfaces simples e amigáveis; 
                        tornando o acesso mais intuitivo, que além de proporcionar uma redução no tempo de treinamento e adaptação, 
                        também estimula e desperta o interesse na equipe em utilizar o sistema.
                    </CardText>
                
                </ContainerText>
            </CardContent>
        </CardContainer>
    )
}

export default CardHome03