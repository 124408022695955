import styled from "styled-components";
import {motion} from 'framer-motion';


export const CardContainer = styled.div `
    display: flex;
    padding: 60px;
    background-color: #fff;
    overflow: hidden;
    
    align-items: center;
    justify-content: center;


    @media (max-width: 900px) {
        padding: 0px;
        flex-direction: column;
        align-items: start
    }

    @media (max-width: 500px) {
        padding-top: 10px;
        
    }

`;

export const CardImage = styled.img `
    width: 50%;
    height: 729px;
    object-fit: cover;
    border: 0.1px solid #141416;


    @media (max-width: 900px) {
        width:100%;
        height: 300px
    }

    @media (max-width: 500px) {
        border-radius: 10px 10px 0 0;
        border: 2px solid #141416
    }


`;

export const CardContent = styled.div `
    background-color: #141416;
    width: 50%;
    height: 729px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1px solid #141416;

    @media (max-width: 900px) {
        width: 100%;
        
    }

    @media (max-width: 500px) {
        border: 2px solid #b9ce12
    }
`;

export const ContainerText= styled(motion.div) `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    transition: 2s ease;
    

    @media (max-width: 1200px) {
        
    }

    @media (max-width: 900px) {
        
    }

    @media (max-width: 500px) {
        
    }

`;

export const CardTitle = styled.span `
    font-size: 52px;
    color: #fff;
    text-align: left;
    font-family: 'poppins   ', sans-serif;
    padding-bottom: 20px;

    @media (max-width: 1200px) {
        font-size: 40px
    }

    @media (max-width: 900px) {
        
    }

    @media (max-width: 500px) {
        
    }
    
`;


export const CardText = styled.span `
    font-size: 16px;
    color: #8a8a8a;
    text-align: justify;
    margin-bottom: 20px;
    font-family:'poppins-extralight', sans-serif;
    line-height: 1.7;

    @media (max-width: 1200px) {
        
    }

    @media (max-width: 900px) {
        
    }

    @media (max-width: 500px) {
        text-align: start;
    }
`;
