import styled from "styled-components";
import {motion} from 'framer-motion';

export const CardContainer = styled.div `
    display: flex;
    width: 100%;
`

export const CardContent = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b9ce12;
`

export const ContainerText = styled(motion.div) `
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 2s ease;

    @media (max-width: 1100px) {
        width: 50%;
    }
`

export const CardImg = styled.img `
    width: 50%;
    object-fit: cover;
`

export const Text = styled.div `
    font-size: 39px;
    color: #ffffff;
    text-align: right;
    font-family: ' poppins', sans-serif;

    @media (max-width: 1100px) {
        font-size: 30px;
    }

    @media (max-width: 500px) {
        font-size: 16px;
    }
`
