import styled from 'styled-components';

export const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const DropdownContent = styled.div`
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;

a {
    color: #8a8a8a;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: 'open-sans', sans-serif;

    &:hover {
        background-color: #ddd;
    }
}
`;

const Dropdown = ({ isOpen, onClick, children }) => {
    return (
        <DropdownContainer>
        {children[0]}
        <DropdownContent isOpen={isOpen}>{children.slice(1)}</DropdownContent>
        </DropdownContainer>
    );
};

export default Dropdown;
