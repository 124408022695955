import React from 'react'
import { CardContainer, ContainerText, Text, Title } from './StyleCard01'

function Card01() {
    return (
        <CardContainer>
            <ContainerText>
                <Title>
                    Quem somos
                </Title>

                <Text>
                    É certo que soluções criativas nascem de uma necessidade; e, conosco, não foi diferente. 
                    Em meados de 2018, a VEPLEX surgiu, de uma inquietação de seus sócios, dentro de uma das maiores empresas de distribuição e logística do Brasil. 
                    O sócio, José Luis Turmina, popularmente conhecido como Kiko, proprietário da Oniz Distribuidora, propôs uma sociedade para 4 funcionários que atuavam dentro da empresa, 
                    no setor de TI.
                </Text>

                <Text>
                    Foi então que os sócios se fizeram o seguinte questionamento: 
                    e se usássemos nosso conhecimento, nossa experiência e nossos sistemas, que tanto ajuda a ONIZ DISTRIBUIDORA, 
                    para facilitar os processos diários de outras empresas do setor? E, a partir deste questionamento, 
                    entendemos que proporcionando isso para outras empresas do mesmo ramo,
                    todos acabariam ganhando e evoluindo. 
                </Text>

                <Text>
                    Dentro da Oniz, um dos pilares sempre foi a TI, buscando inovação e sempre visando melhorar os resultados; 
                    tanto que o sistema interno sempre impressonou (e ainda impressiona) fornecedores e clientes. 
                </Text>

                <Text>
                    Foi com essa visão e é essa a intenção da VEPLEX, <b>agregar conhecimento e valor </b> às empresas do ramo de distribuição.
                </Text>
            </ContainerText>
        </CardContainer>
    )
}

export default Card01