

import { motion } from "framer-motion";
import styled from "styled-components";

export const CardContainer = styled.div `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    
`
export const CardContent = styled(motion.div)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    transition: 2s ease;
    padding-top: 20px;
    padding-bottom: 20px;


    @media (max-width: 500px) {
        flex-direction: column;
    }
`


export const ContainerImg = styled.div `
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    @media (max-width: 500px) {
        width: 100%;
    }
`

export const Image = styled.img `
    width: 40%;
    object-fit: cover;
` 

export const ContainerText = styled.div `
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;

    @media (max-width: 1100px) {
        padding-top: 20px;
    }

    @media (max-width: 780px) {
        width: 70%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`

export const Text = styled.span `
    font-size: 20px;
    font-family: 'open-sans',sans-serif;
    text-align: left;
    padding-bottom: 10px;
    color: #8a8a8a;

    @media (max-width: 1100px) {
        font-size: 16px;
    }
`

