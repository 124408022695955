import React from 'react'
import { CardContainer, CardContent, ContainerText, Image } from './StyleCard05'
import { useInView } from 'react-intersection-observer';

function Card05() {
    const array = [
        {
            title: 'Automatização',
            text: 'Otimize os processos de vendas, economizando tempo e aumentando a eficiência da sua empresa.'
        },
        
        {
            title: 'Produtividade',
            text: 'Permitindo que o time comercial realizem mais vendas em menos tempo e melhorando experiência para o seu cliente.'
        },

        {
            title: 'Gerenciamento',
            text: 'Acompanhe de forma organizada e simples o desempenho da sua equipe, gere relatórios para tomadas de decisões assertivas.'
        },

        {
            title: 'Facilidade',
            text: 'Layout simples, intuitivo e prático. Não perca tempo com integrações complexas e que não atendem a sua necessidade.'
        }
    ]

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    }

    return (
        <CardContainer ref={ref}>
            <CardContent>
                {array.map((item, index) => (
                    <ContainerText align={index%2 === 0 ? 'flex-start' : 'flex-end'}
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={animationVariants}
                    >
                        <h1>{item.title}</h1>
                        <p>{item.text}</p>
                    </ContainerText>
                ))}
                <Image src={('./cel.png')}/>
            </CardContent>
        </CardContainer>
    )
}

export default Card05