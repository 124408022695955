import styled from 'styled-components';
import {motion} from 'framer-motion';

export const BannerContainer = styled.div`
    width: 100%;
    height: 100vh; 
    display: flex
`;

export const BannerImage = styled.img`
    width: 100%;
    object-fit:cover;
`;

export const ContainerText = styled(motion.div)`
    position: absolute;
    top: 30%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 41%;
    transition: 2s ease;
`;

export const DivTitle = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;

    @media (max-width: 1200px) {
        
    }

    @media (max-width: 768px) {
        
    }
    
`

export const DivText = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;

    @media (max-width: 1200px) {
        
    }

    @media (max-width: 768px) {
        
    }
`

export const BannerTitle = styled.span `
    font-size: 52px;
    color: #fff;
    font-family: 'poppins', sans-serif;
    letter-spacing: 2px;

    @media (max-width: 1200px) {
        font-size: 40px
    }

    @media (max-width: 768px) {
        font-size: 27px;
    }
`

export const BannerText = styled.span `
    font-size: 24px;
    color: #fff;
    font-family: 'open-sans', sans-serif;
    text-align: left;
    margin-bottom: 15px;
    letter-spacing: 1.5px;
    font-weight: 400;
    
    @media (max-width: 1200px) {
        font-size: 16px;
    }
`