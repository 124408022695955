
import styled from "styled-components";

export const CardContainer = styled.div `
    display: flex;
    width: 100%;
    flex-direction: column;
`
export const ContainerText = styled.div `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
`
export const DivText = styled.div `
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
`

export const Text = styled.span ` 
    font-size: 28px;
    color: #8a8a8a;
    font-family: 'poppins', sans-serif;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`

export const ContainerContent = styled.div ` 
    display: flex;
    width: 100%;

    @media (max-width: 500px) {
        flex-direction: column;
    }
`
export const BannerText = styled.div ` 
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 500px) {
        width: 100%;
    }
`

export const DivTextForm = styled.div ` 
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: right;
    justify-content: center;

    @media (max-width: 500px) {
        width: 100%;
        padding-bottom: 20px;
    }
`

export const TitleForm = styled.span ` 
    font-size: 30px;
    color: #141416;
    padding-bottom: 5px;
    text-align: right;

    @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
        padding-bottom: 10px;

    }
`

export const TextForm = styled.span `
    font-size: 16px;
    color: #141416;
    font-family: 'open sans', sans-serif;
    padding-bottom: 10px;
    text-align: right;

    @media (max-width: 768px) {
        font-size: 14px;
        text-align: center;
        padding-bottom: 5px;
    }
`

export const ContainerForm = styled.div ` 
    display: flex;
    width: 60%;
    background-color: #008afc;
    padding: 50px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 100px;

    @media (max-width: 1200px) {
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 768px) {
        padding: 20px;
    }

    @media (max-width: 500px) {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`


export const Form = styled.form `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;  

    @media (max-width:700px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        justify-content: center;
    }
`

export const DivForm = styled.div`
    display: flex;
    flex-Direction: row;
    width: 100%;
    justify-content: space-between;

    @media(max-width:1200px){
        flex-direction: column;
    }
`;

export const DivInputName = styled.div `
    display: flex;
    flex-direction: column;

    @media(max-width:768px){
        width: 100%;
    }
`

export const DivInputEmpresa = styled.div `
    display: flex;
    flex-direction: column;
    align-items: start;

    @media(max-width:700px){
        align-items: flex-start;
    }
`

export const DivForm2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    

    @media(max-width:700px){
        width: 80%;
        justify-content: start;
    }
`;


export const Input = styled.input `
    width: 92%;
    height: 37px;
    border-radius: 20px;
    background-color: #0067bb;
    margin-bottom: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #ccc;
    color: #fff;

    

    @media (max-width: 700px) {
        width: 80%;
        height: 37px;
        
    }

    @media (max-width: 500px) {
        
    }

`;

export const InputEmail = styled.input `
    width: 100%;
    height: 37px;
    border-radius: 20px;
    background-color: #0067bb;
    margin-bottom: 10px;
    padding-left: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #ccc; 
    color: #fff;
`


export const Label = styled.span `
    font-size: 14px;
    color: #fff;
    font-family: 'open-sans', sans-serif;
    width: 330px;
    padding-bottom: 2px;

    @media (max-width: 1200px) {
        
    }

    @media (max-width: 728px) {
        width: 230px;
    }

    @media (max-width: 500px) {
        
    }
`

export const DivSubmitButton = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 700px) {
        
        justify-content: center;
    }
`

export const SubmitButton = styled.button`
    width: 329px;
    height: 36px;
    background-color: #a4b956;
    color: #000;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        width: 80%;
        
    }
`;
export const TextButton = styled.span `
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    font-family: 'open-sans', sans-serif;
    width: 330px;
    padding-bottom: 2px;

    @media (max-width: 1200px) {
        
    }

    @media (max-width: 900px) {
        
    }

    @media (max-width: 500px) {
        
    }
`