import React from 'react'
import { CardContainer, CardContent, ContainerImg, ContainerText, Image,Text } from './StyleCard03'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useInView } from 'react-intersection-observer';

function Card03() {
    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    }

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (

        <CardContainer ref={ref}>
            <CardContent 
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={animationVariants}
            >
                <ContainerImg>
                    <Image src='./celular.png'/>
                </ContainerImg>
                
                <ContainerText 
                >
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Online/offline</Text> 
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/>  Controle de jornada de trabalho</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Check-in e check-out com controle de localização</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Controle e acompanhamentos de metas</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Registro de fotos com geolocalização, data e horário</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Reabastecimento de loja</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Mix ideal por cliente/fornecedor</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Lançamento e controle de ponto extra</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Dashboard de análise de dados</Text>
                    <Text> <NavigateNextIcon style={{color: '#b9ce12', width: '30px', height: '20px'}}/> Cadastro de planogramas</Text>
                </ContainerText>
            </CardContent>
        </CardContainer>
    )
}

export default Card03