import styled from "styled-components";

export const ContainerNossasSolucoes = styled.div `
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
`


