export const info_Cards = [
        {
                
                texto: "Entregue uma jornada de trabalho prática e eficiente para seus promotores, gerenciando metas e objetivos, permitindo registros de fotos e ações de merchandising em tempo real. Com o apoio de uma dashboard intuitiva e com a possibilidade de cadastro de planogramas, tire as melhores fotos e garanta altos resultados do time de promotores.",
                imagem: './ativo1.png'
                

        },

        {
                
                texto: "Com o UP Vendas sua equipe comercial terá tudo que precisa na palma da mão, uma ferramenta que permite, de forma automatizada, que os vendedores e clientes negociem de forma simples e intuitiva. Um aplicativo pensando em facilitar sua vida, buscando agilidade e praticidade para as vendas e, ainda, propiciar aos gestores maior controle sobre todas as etapas do processo comercial, gerando informações precisas em tempo real para a tomada de decisões e resultados mais assertivo",
                imagem: './teste.png'
                
        }, 

        {
                texto: "Com o UP Rotas, você pode planejar o roteiro de visitas da sua equipe comercial com facilidade. Nossa ferramenta permite que você crie rotas personalizadas para seu time comercial, de acordo com a localização de seus clientes e as prioridades de vendas, economizando dinheiro e ganhando tempo, com rotas inteligentes e otimizadas. Com o acompanhamento por geolocalização, você pode monitorar em tempo real o deslocamento da equipe de vendas e ter uma visão completa do progresso das visitas",
                imagem: './ativo3.png'
        }


]   
