import React from 'react'
import { CardContainer, ContainerText, Text } from './StyleCard03'

function Card03() {
    return (
        <CardContainer>
            <ContainerText>
                <Text>
                    A partir da criação da Empresa, em dezembro 2018, ficamos “encubados” 
                    dentro da Oniz Distribuidora, vivendo intensamente nosso propósito de facilitar os processos dentro das distribuidoras; 
                    buscando desenvolver soluções de verdade, de acordo com o dia-dia interno (realização de processos) e externo (entrando na rotina do vendedor).
                    Com toda a base construída dentro da <b>maior Distribuidora do Rio Grande do Sul</b>, em 2022 sentimos a necessidade de alcançar voos mais altos. 
                </Text>

                <Text>
                    Foi então que, em fevereiro de 2022, a VEPLEX passou a ter sua sede própria, com apenas 7 colaboradores. 
                    A partir daí, procurando dar suporte e agilidade aos atendimentos, fomos agregando colaboradores, sempre buscando passar 
                    todos os nossos conhecimentos, trazendo para eles a vivência e experiência de quem entende o setor.
                </Text>

                <Text>
                    <b>A nossa maior missão é agregar qualidade e promover evolução tecnológica para nossos clientes, 
                    de forma prática e ágil.</b>
                </Text>
            </ContainerText>
        </CardContainer>
    )
}

export default Card03