import React from 'react'
import BannerHome from '../../components/banner-homepage/BannerHome'
import { ContainerText, Title2, Title3, TitleBold } from './StyleHome'
import CardHome01 from '../../components/card-home01/CardHome01'
import CardHome02 from '../../components/card-home02/CardHome02'
import CardHome03 from '../../components/card-home03/CardHome03'
import CardHome04 from '../../components/card-home04/CardHome04'
import CardHome05 from '../../components/card-home05/CardHome05'
function Home() {
    return (
        <>
            <BannerHome />
                
            <ContainerText >
                <TitleBold >
                    DA EXPERIÊNCIA A EXCELÊNCIA
                </TitleBold>

                <Title2>
                    SOLUÇÕES QUE ENTENDEM DE VERDADE O SEU NEGÓCIO!
                </Title2>

                <Title3>
                    CRIAMOS SOLUÇÕES REAIS E EFETIVAS PARA OS
                </Title3>

                <Title3>
                    DESAFIOS DIÁRIOS DE DISTRIBUIDORAS E ATACADISTAS.
                </Title3>
            </ContainerText>

            <CardHome01 />

            <CardHome02 />
            
            <CardHome03 />

            <CardHome04 />

            <CardHome05 />
        </>
        
    )
}

export default Home