import React from 'react'
import { Subtitle, CardContainer, ContainerText, Title, Text, ContainerBlack, Img, DivGreen, TextWhite, TextGray, ContainerImg  } from './styleCard05'
import { useInView } from 'react-intersection-observer';

function Card05() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const animationVariants = {
        hidden: { x: -1000, opacity: 0 },
        visible: { x: 0, opacity: 1 },
    }; 


    return (
        <CardContainer 
            ref={ref} 
        >
            <ContainerText 
                
                initial="hidden" 
                animate={inView ? 'visible' : 'hidden'} 
                variants={animationVariants}
            >
                <Title>
                    Da experiência a excelência. 
                </Title>

                <Subtitle>
                    Soluções que entendem de verdade o seu negócio!
                </Subtitle>

                <Text>
                    Simplificamos e facilitamos o gerenciamento do seu negócio. Simples, assim. E tudo na palma da sua mão. 
                </Text>

            </ContainerText>

            <ContainerBlack
                ref={ref} 
                initial="hidden" 
                animate={inView ? 'visible' : 'hidden'} 
                variants={animationVariants}
            >
                <ContainerImg>
                    <Img src={require('../../../images/kiko_Card05.jpg')} />
                    <DivGreen>
                        <TextWhite>José Luis Turmina</TextWhite>
                        <TextGray>CEO</TextGray>
                    </DivGreen>
                </ContainerImg>

                <ContainerImg>
                    <Img src={require('../../../images/ieda_card05.jpg')} />
                    <DivGreen>
                        <TextWhite>Ieda Turmina</TextWhite>
                        <TextGray>CFO</TextGray>
                    </DivGreen>
                </ContainerImg>

                <ContainerImg>
                    <Img src={require('../../../images/stevan.jpg')} />
                    <DivGreen>
                        <TextWhite>Stevan Costa </TextWhite>
                        <TextGray>CCO</TextGray>
                    </DivGreen>
                </ContainerImg>

                <ContainerImg>
                    <Img src={require('../../../images/lucas.jpg')} />
                    <DivGreen>
                        <TextWhite>Lucas Altmann</TextWhite>
                        <TextGray>CTO</TextGray>
                    </DivGreen>
                </ContainerImg>

                <ContainerImg>
                    <Img src={require('../../../images/rodrigo.jpg')} />
                    <DivGreen>
                        <TextWhite>Rodrigo Piovesan</TextWhite>
                        <TextGray>CIO</TextGray>
                    </DivGreen>
                </ContainerImg>
            </ContainerBlack>
        </CardContainer>
    )
}

export default Card05