import React from 'react'
import CardHome02 from '../../components/card-home02/CardHome02'
import { ContainerNossasSolucoes } from '../../components/soluções/Card01/StyleCard01'

function NossasSolucoes() {
  return (
    <ContainerNossasSolucoes>
      <CardHome02 />
    </ContainerNossasSolucoes>
  )
}

export default NossasSolucoes