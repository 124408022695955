import { motion } from "framer-motion";
import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #b9ce12;
`

export const CardContent = styled(motion.div)`
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`;

export const ContainerText = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: 40%;
    transition: 2s ease;


    @media (max-width: 500px) {
        width: 50%;
    }
`
export const Text = styled.span `
    font-size: 34px;
    font-family: 'Poppins';
    text-align: right;
    color: #ffffff;

    @media (max-width: 1100px) {
        font-size: 24px;   
    }

    @media (max-width: 768px) {
        font-size: 20px;   
    }

    @media (max-width: 500px) {
        font-size: 15px;
        text-align: left;
    }
`
export const Image = styled.img `
    width: 50%;
    object-fit: cover;
    @media (max-width: 500px) {
        width: 100%;
    }
`