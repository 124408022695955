import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 900px) {
        
        
    }

    @media (max-width: 500px) {
        
    }

`

export const Carousel = styled.div `
    cursor: grab;
    max-width: 600px;
    
    @media (max-width: 1200px) {
    }
    
    @media (max-width: 900px) {
        
        
    }
    
    @media (max-width: 500px) {
        max-width: 300px;
    }

`

export const Imagem = styled.img `
    max-width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
    pointer-events: none;

    @media (max-width: 900px) {
        max-width: 90%;
    }
    

    @media (max-width: 500px) {
        max-width: 50%;
    }
`
