import React from 'react'
import Card01 from '../../components/contato/Card01/Card01'
import Card02 from '../../components/contato/Card02/Card02'


function Contato() {
  return (
    <>
      <Card01 />
      <Card02 />
      
    </>
  )
}

export default Contato