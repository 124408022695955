import styled from "styled-components";

export const BannerContainer = styled.div`
    position: relative;
    width: 100%;
`;


export const BannerImage = styled.img`
    width: 100%;
    max-height: 600px; /* Altura máxima ajustável conforme necessário */
    display: block;
    object-fit: cover;
`

export const OverlayImage = styled.img `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    opacity: 0.8; /* Ajuste conforme necessário */
`