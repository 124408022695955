import styled from "styled-components";

export const CardContainer = styled.div `
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;

`

export const ContainerContent = styled.div `
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media (max-width: 1200px) {
    }
    
    @media (max-width: 900px) {
        flex-direction: column;
        
    }
`
export const ContainerText = styled.div `
    width: 40%;
    text-align: right !important;

    @media (max-width: 900px) {
        width: 100%;
        text-align: center !important;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        
        
    }
`
export const Text = styled.span `
    font-size: 25px;
    background-color: #0000;
    font-family: 'poppins', sans-serif;
    text-align: right !important;
    font-weight: 500;

    @media (max-width: 500px) {
    font-size: 15px;
    }
`

export const Line = styled.div `
    width: 2px ;
    z-index: 10;
    background-color: #B9CE12;
    height: 90px;
    margin-right: 10px;
    margin-left: 5px;

    
    @media (max-width: 900px) {
        width: 40%;
        height: 3px;
        background-color: #B9CE12;
        margin-top: 1px;
        margin-bottom: 10px;
        margin-right: 0px;
        margin-left: 0px;   
    }

    @media (max-width: 500px) {
        width: 30%;
    }
    
`