import styled from "styled-components";
import {motion} from 'framer-motion';

export const CardContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    
    display: flex;
    align-items: center;
    
`;

export const GeneralContainer = styled.div `
    padding: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 1200px) {
        padding: 40px;
    }
    @media (max-width: 900px) {
        padding: 0px;
    }

`;

export const CardContent = styled.div `
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 550px;
    align-items: center;
    justify-content: center;    
    background-color: #703189;
    align-items: center;
    gap:20px;

    @media (max-width: 1000px) {
        width: 100%;
    }

    @media (max-width: 600px) {
        height: unset;
        padding-top: 10px;
        padding-bottom: 10px;
    }

`;

export const ContainerText = styled(motion.div) `
    margin-inline: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: 2s ease;

    div {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 1320px){
        margin-inline: 10%;
    }

`;

export const CardTitle = styled.span`
    font-size: 41px;
    color: #ffff;
    font-family:'poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 30px;
    letter-spacing: 1.5px;
    
    @media (max-width: 900px) {
        font-size: 20px
    }

`;

export const CardText = styled.span`
    font-size: 18px;
    color: #fff;
    text-align: justify;
    font-family:'open-sans', sans-serif;
    white-space: pre-wrap;
    line-height: 25px;
    
    @media (max-width: 600px) {
        font-size: 10px;
        line-height: 15px;

    }
`;

export const CardImage = styled.img`
    width: 50%;
    height: 550px;
    object-fit: cover;
    
    @media (max-width: 1000px) {
        display: none;
    }

`;