
import { motion } from "framer-motion";
import styled from "styled-components";

export const CardContainer = styled.div `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #008afc;
    
`
export const ContainerText = styled(motion.div) `
    width: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;

`
export const Text = styled.span `
    font-size: 42px;
    color: white;
    font-family: 'poppins', sans-serif;
    text-align: center;
    

    @media (max-width: 500px) {
        font-size: 30px;
        padding-bottom: 10px;
    }
`