import styled from "styled-components";

export const BannerContainer = styled.div`
    width: 100%;
    display: flex
`;

export const BannerImage = styled.img`
    width: 100%;
    object-fit:cover;
`;