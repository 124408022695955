import React from 'react'
import { BannerContainer, ContainerText, Image, Overlay, Text } from './StyleBanner'

function Banner() {
    return (
        <BannerContainer>
            <Image src='banner.jpg'/>
            <Overlay src= './uppromotor.png' />
            <ContainerText> <Text>Solução perfeita para manter o seus promotores  todo o vapor</Text> </ContainerText>
        </BannerContainer>
    )
}

export default Banner