import React from 'react'
import { BannerContainer, BannerImage } from './StyleBanner'

function Banner() {
  return (
    <BannerContainer>
      <BannerImage src={require('../../../images/banner-quem-somos.png')} alt="Banner da Home" />
    </BannerContainer>
  )
}

export default Banner