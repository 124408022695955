import React, { useState } from 'react'
import { BannerText, 
    CardContainer, 
    ContainerContent, 
    ContainerForm, 
    ContainerText, 
    DivForm, 
    DivForm2, 
    DivInputEmpresa, 
    DivInputName, 
    DivSubmitButton, 
    DivText, 
    DivTextForm, 
    Form, Input, 
    InputEmail, 
    Label, 
    SubmitButton, 
    Text, 
    TextButton, 
    TextForm, 
    TitleForm 
} from './StyleCard06'

import Swal from 'sweetalert2'
import { useInView } from 'react-intersection-observer';
import emailjs from '@emailjs/browser';

function Card06() {
    const [name, setName] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [email, setEmail] = useState('')


    function alertError() {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Complete todos os campos!",
        });
    }

    function alertSuccess() {
        Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Email enviado com sucesso!",
        });
    }

    

    function sendEmail(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        
        if(name === '' || empresa === '' || email === '') {
            alertError()
            return
        }

        alertSuccess();

        

        const templateParams = {
            from_name: name, 
            empresa: empresa,
            email: email

        }

        emailjs.send("SiteVeplex","template_rzzo3ur", templateParams, "eYdC7GosxHsujkgIV")
        .then((response) => {
            console.log("EMAIL ENVIADO", response.status, response.text); 
            setName('');
            setEmpresa('');
            setEmail('');
        }, (err) => {
            console.log("ERRO AO ENVIAR EMAIL", err); 
        });
    }

    const [ref, inView] = useInView({
        triggerOnce: true,
    });


    return (
        <CardContainer>
            <ContainerText>
                <DivText>
                    <Text>
                        Não perca mais tempo com processos manuais, complexos e tediosos. 
                        Conheça nosso aplicativo de vendas e potencialize agora mesmo o crescimento da sua empresa.
                    </Text>
                </DivText>
            </ContainerText>
            <ContainerContent>
                <BannerText>
                    <DivTextForm>
                        <TitleForm>
                            Fale agora com 
                        </TitleForm>
                        <TitleForm style={{paddingBottom: '20px'}}>
                        nossos consultores
                        </TitleForm>
                        <TextForm>
                            Entenda melhor como impulsionar 
                        </TextForm>
                        <TextForm>
                            seus resultados com nossas soluções.
                        </TextForm>
                    </DivTextForm>
                </BannerText>

                <ContainerForm >
                    <Form onSubmit={sendEmail}>
                        <DivForm>
                            <DivInputName>
                                <Label>Nome</Label>
                                <Input 
                                    type="text" 
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </DivInputName>

                            <DivInputEmpresa>
                                <Label>Empresa</Label>
                                <Input 
                                    type="text" 
                                    onChange={(e) => setEmpresa(e.target.value)}
                                    value={empresa}
                                />
                            </DivInputEmpresa>

                        </DivForm>

                        <DivForm2>
                            <Label>E-mail</Label>

                            <InputEmail 
                                type="text"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </DivForm2>

                        <DivSubmitButton>
                            <SubmitButton type='submit'> <TextButton> ENVIAR AGORA MESMO </TextButton> </SubmitButton>
                        </DivSubmitButton>
                    </Form>
                </ContainerForm>
            </ContainerContent>
        </CardContainer>
    )
}

export default Card06