import React from 'react';
import { useInView } from 'react-intersection-observer';
import { CardContainer, CardText, ContainerCardProducts, ContainerText } from './styleCardHome02';
import CardProduto from '../card-produtos/CardProduto';
import { info_Cards } from '../../components/card-produtos/utils/card';

type Card = {
  imagem: string;
  texto: string;
};

const animationVariants = {
  hidden: { x: -1000, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

function CardHome02() {
  const infoCards = info_Cards;
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <CardContainer ref={ref}>
      
      <ContainerText
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={animationVariants}
      >
        <CardText>
          SOLUÇÕES COMPLETAS PARA GERAR RESULTADOS EFETIVOS
        </CardText>
      </ContainerText>

      <ContainerCardProducts>

        {infoCards.map((card: Card, index: number) => (
          <CardProduto
            imagem={card.imagem}
            texto={card.texto}
            key={index}
            
          />
        ))}
        
      </ContainerCardProducts>
    </CardContainer>
  );
}

export default CardHome02;
