import React from 'react'
import { CardContainer, DivTitle, Title2 } from './StyleCard02'

function Card02() {
    return (
        <CardContainer>
            <DivTitle>
                <Title2>A solução perfeita e ágil para manter sua equipe de promotores</Title2>
                <Title2>produtiva e gerando engajamento de marca em seus clientes.</Title2>
            </DivTitle>
        </CardContainer> 
    )
}

export default Card02