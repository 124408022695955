import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
`

export const DivTitle = styled.div `
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

export const Title2 = styled.span `
    font-size: 26px;
    color: #008afc;
    text-align: center;
`