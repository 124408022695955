import { motion } from "framer-motion";
import styled from "styled-components";


export const CardContainer = styled.div `
    width: 100%;
    height: 100%;
    background-color: #b9ce12;
    display: flex;
    align-items: center;
    justify-content: center;
    
`
export const ContainerText = styled(motion.div) `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 150px;
    padding-right: 150px;
    
    @media(max-width: 500px) {
        padding: 40px;
        width: 100%;
    }
`;

export const TextWhite = styled.span `
    font-size: 31px;
    padding-bottom: 5px;
    color: #fff;
    font-family: 'poppins', sans-serif;

    @media(max-width: 500px) {
        font-size: 20px;
    }

`


export const TextPurple = styled.span `
    font-size: 31px;
    color: #703189;
    font-family: 'poppins', sans-serif;


    @media(max-width: 500px) {
        font-size: 20px;
    }
    
`