import * as React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Carousel, Container, Imagem } from "./StyleCarrossel";

const images = [
  require('../../images/logo-oniz.png'),
  require('../../images/logo-ofertao.png'),
  require('../../images/logo-site-friolack.png'),
  require('../../images/logo-trudel.png'),
  require('../../images/logo-polina.jpg')
];

export default function Carrossel() {
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: true,
    mode: "free",
    slides: {
      perView: 3,
    },
    created(s) {
      s.moveToIdx(5, true, { duration: 50000, easing: (t: number) => t });
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, { duration: 50000, easing: (t: number) => t });
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, { duration: 50000, easing: (t: number) => t });
    },
  });

  return (
    <Container>
      <Carousel ref={ref} className="keen-slider">
        {images.map((image, index) => (
          <div key={index} className="keen-slider__slide conteiner_slider">
            <Imagem src={image} alt={`logo ${index}`} />
          </div>
        ))}
      </Carousel>
    </Container>
  );
}
