import React from 'react';
import { BannerContainer, BannerImage, BannerText, BannerTitle, ContainerText, DivText, DivTitle } from './StyleBannerHome';



function BannerHome() {
    return (
        <BannerContainer>
            <BannerImage src={require('../../images/BANNER (1).png')} alt="Banner da Home" />
                <ContainerText
                    initial={{ x: -1000, opacity: 0 }} // Posição inicial fora da tela à esquerda e opacidade 0
                    animate={{ x: 100, opacity: 1 }} // Posição final, movendo-se para a direita e opacidade 1
    
                >
                    <DivTitle>
                        <BannerTitle>
                            A solução certa para simplificar e otimizar o dia a dia da sua equipe e do seus clientes
                        </BannerTitle>
                    </DivTitle>
                    <DivText>
                        <BannerText>
                            A ferramenta perfeita para escalar suas vendas e rentabilizar muito mais o seu negócio
                        </BannerText>
                    </DivText>
                </ContainerText>
                
        </BannerContainer>
    );
}

export default BannerHome;
