import React from 'react'
import { CardContainer, ContainerText, Text } from './StyleCard01'

function Card01() {
    return (
        <CardContainer>
            <ContainerText>
                <Text>
                    Nossos Contatos
                </Text>
            </ContainerText>
        </CardContainer>
    )
}

export default Card01